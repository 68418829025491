import React from 'react';
import Header from './components/Header';
import Experience from './components/Experience';
import Education from './components/Education';
import Skills from './components/Skills';
import Contact from './components/Contact';
import CursorEffect from './components/CursorEffect';
import Projects from './components/Projects';

function App() {
  return (
    <div className="dark">
      <CursorEffect />
      <div className="min-h-screen bg-primary">
        <Header />
        <div className="max-w-7xl mx-auto px-4 pb-20">
          <div className="flex flex-col gap-14">
            <div>
              <Experience />
            </div>
            <div>
              <Projects />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-items-center">
              <Education />
              <Skills />
              <Contact />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
