import React from 'react';
import Tilt from 'react-parallax-tilt';

const Education: React.FC = () => {
  return (
    <div className="w-full">
      <h2 className="section-title">Education</h2>
      <Tilt className="Tilt" tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05}>
        <div className="bg-secondary p-6 rounded-lg shadow-lg min-h-[250px]">
          <h3 className="text-xl font-semibold mb-2">Le Wagon, London UK</h3>
          <p className="text-sm text-accent mb-4">Full Stack Web Development</p>
          <p className="text-base mb-4">Learned up-to-date languages, technologies, and skills to become a Full Stack Web Developer.</p>
          <p className="text-sm text-gray-400">Jun 2022 - Dec 2022</p>
        </div>
      </Tilt>
    </div>
  );
};

export default Education;
