import React from 'react';
import { motion } from 'framer-motion';

const Header: React.FC = () => {
  const scrollToExperience = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const experienceSection = document.getElementById('experience');
    if (experienceSection) {
      experienceSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <motion.header 
      className="min-h-screen flex items-center justify-center bg-primary"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="text-center">
        <motion.h1 
          className="text-6xl font-bold mb-4"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Gianluca Galli
        </motion.h1>
        <motion.h2 
          className="text-2xl text-accent mb-8"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          Solution Engineer & Software Developer
        </motion.h2>
        <motion.div 
          className="flex justify-center gap-4"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <a 
            href="#experience" 
            onClick={scrollToExperience}
            className="px-6 py-2 bg-accent text-white rounded-full hover:bg-opacity-90 transition-all"
          >
            View Experience
          </a>
          <a 
            href="/assets/gianluca_galli_resume.pdf"
            download
            className="px-6 py-2 border border-accent text-accent rounded-full hover:bg-accent hover:text-white transition-all"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Resume
          </a>
          <a href="mailto:m.gianlucagalli@gmail.com" className="px-6 py-2 border border-accent text-accent rounded-full hover:bg-accent hover:text-white transition-all">
            Contact Me
          </a>
        </motion.div>
      </div>
    </motion.header>
  );
};

export default Header;
