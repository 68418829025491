import React from 'react';
import Tilt from 'react-parallax-tilt';

const Projects = () => {
  const projects = [
    {
      title: "PodPrep AI",
      description: "An AI-driven platform that helps podcasters discover and connect with relevant expert guests to create high-quality content.",
      technologies: ["TypeScript", "Node.js", "React", "GROQ", "OpenAI", "Google Custom Search API", "YouTube Data API", "Spotify API"],
      link: "#"
    }
  ];

  return (
    <div id="projects" className="w-full">
      <section>
        <h2 className="section-title">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {projects.map((project, index) => (
            <Tilt
              key={index}
              tiltMaxAngleX={5}
              tiltMaxAngleY={5}
              glareEnable={true}
              glareMaxOpacity={0.1}
              scale={1.02}
            >
              <div className="bg-secondary p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-base mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.technologies.map((tech, techIndex) => (
                    <span 
                      key={techIndex} 
                      className="px-3 py-1 bg-primary rounded-full text-sm text-accent"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-accent hover:text-blue-300 transition-colors"
                >
                  View Project →
                </a>
              </div>
            </Tilt>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Projects;
