import React from 'react';
import { motion } from 'framer-motion';
import Tilt from 'react-parallax-tilt';

const experiences = [
  {
    title: 'Solution Engineer',
    company: 'Dear Chappie SAAS',
    url: 'dearchappie.com',
    description: 'Designed Dearchappie AI assistant, cutting podcaster prep time by 30 minutes via n8n, NocoDB, Perplexity, and OpenAI. Reached 300 podcasters with targeted cold emails, using Clay.com to refine ICP and automate outreach.',
    duration: 'Aug 2022 - Present',
  },
  {
    title: 'Co-founder & Automation Consultant',
    company: 'Dear Chappie Consulting Firm',
    description: 'Secured 5 clients and 3 monthly meetings in Q1 with 20% email response rates and a 25% lead-to-client conversion. Designed workflows that saved 3 hours/day for a recruiting agency, improving lead accuracy by 95%. Delivered £1500/month savings and 98% data accuracy by automating cross-verification of 400 client records with Companies House.',
    duration: 'Aug 2022 - Present',
  },
  {
    title: 'Operational Manager',
    company: 'Frizzante',
    description: 'Developed an AI tool integrating Gmail, QuickBooks, OpenAI, and Google Calendar to automate customer inquiries, saving 30 minutes daily and cutting response times by 90%.',
    duration: 'March 2022 - Present',
  },
];

const Experience: React.FC = () => {
  return (
    <div id="experience" className="w-full">
      <section>
        <h2 className="section-title">Experience</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {experiences.map((exp, index) => (
            <Tilt className="Tilt" tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05} key={index}>
              <motion.div
                className="bg-secondary p-6 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2, duration: 0.5 }}
              >
                <h3 className="text-xl font-semibold mb-2">{exp.title}</h3>
                <p className="text-sm text-accent mb-4">{exp.company}</p>
                <p className="text-base mb-4">{exp.description}</p>
                {exp.url && (
                  <p className="text-sm text-accent mb-4">
                    <a href={`https://www.${exp.url}`} target="_blank" rel="noopener noreferrer" className="hover:underline">
                      {exp.url}
                    </a>
                  </p>
                )}
                <p className="text-sm text-gray-400">{exp.duration}</p>
              </motion.div>
            </Tilt>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Experience;
