import React from 'react';
import Tilt from 'react-parallax-tilt';

const Skills: React.FC = () => {
  return (
    <div className="w-full">
      <h2 className="section-title">Skills</h2>
      <Tilt className="Tilt" tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05}>
        <div className="bg-secondary p-6 rounded-lg shadow-lg min-h-[250px]">
          <ul className="list-disc pl-5">
            <li className="mb-2">HTML5 & CSS3, Tailwind, JavaScript, React.js, TypeScript</li>
            <li className="mb-2">RoR, Node.js, PostgreSQL, RESTful APIs</li>
            <li className="mb-2">Git & Github</li>
            <li className="mb-2">Workflow Automation: n8n, UiPath</li>
            <li className="mb-2">Sales & Growth Hacking, Startups</li>
          </ul>
        </div>
      </Tilt>
    </div>
  );
};

export default Skills;
