import React from 'react';
import Tilt from 'react-parallax-tilt';

const Contact: React.FC = () => {
  return (
    <div className="w-full">
      <h2 className="section-title">Contact</h2>
      <Tilt className="Tilt" tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1.05}>
        <div className="bg-secondary p-6 rounded-lg shadow-lg min-h-[250px]">
          <p className="mb-4">London, UK</p>
          <p className="mb-4"><a href="mailto:m.gianlucagalli@gmail.com" className="text-accent">m.gianlucagalli@gmail.com</a></p>
          <p className="mb-4"><a href="https://www.linkedin.com/in/giangalli" className="text-accent" target="_blank" rel="noopener noreferrer">Linkedin</a></p>
          <p className="mb-4"><a href="https://github.com/Mac0nia" className="text-accent" target="_blank" rel="noopener noreferrer">GitHub</a></p>
          <p className="mb-4">+44 7578930408</p>
        </div>
      </Tilt>
    </div>
  );
};

export default Contact;
